import {Routes} from '@angular/router';
import {LoadCourseDetailResolver} from '../services/load-course-detail.resolver';
import {UploadOngoingGuard} from './services/upload-ongoing.guard';
import {DripScheduleConfigService} from '../services/drip-schedule-config.service';

export const editCoursesRoutes: Routes = [
  {
    path: ':courseUrl/edit',
    loadComponent: () => import('./edit-course/edit-course.component').then(m => m.EditCourseComponent),
    resolve: {
      course: LoadCourseDetailResolver
    },
    canDeactivate: [UploadOngoingGuard],
    providers: [
      UploadOngoingGuard,
      DripScheduleConfigService
    ],

  },
];
