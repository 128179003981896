import {ErrorHandler, inject, Injectable} from '@angular/core';
import { TrackJS } from "trackjs";
import { categorizeError } from './trackjs.service';

@Injectable({
  providedIn: "root"
})
export class TrackJsErrorHandler implements ErrorHandler {

  handleError(error:any) {

    categorizeError(error);

    TrackJS.track(error.originalError || error);
  }
}
