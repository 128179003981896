import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {selectTenantInfo} from '../store/selectors';
import {filter, map, switchMap} from 'rxjs/operators';
import {SitePagesService} from "../page-builder/site-pages/services/site-pages.service";
import {CustomPage} from "../models/page-builder/custom-page.model";
import {TenantInfo} from "../models/tenant.model";
import { LoadingService } from '../shared/services/loading.service';

/**
 *
 * If the home page is activated, then display it otherwise redirect to the courses page.
 *
 */


@Injectable()
export class CheckIfHomePageActiveGuard  {

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private loading: LoadingService,
    private sitePagesService: SitePagesService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.loading.loadingOn();
    return this.store.pipe(
      select(selectTenantInfo),
      filter(tenant => !!tenant),
      // here
      switchMap((tenantInfo) => {
        return this.sitePagesService.getHomePage$(tenantInfo?.['id'])
          .pipe(
            map(pages => {
              return { page: pages?.[0], tenant: tenantInfo } as  {page: CustomPage, tenant: TenantInfo}
            })
          )
      }),
      // here
      map(({page, tenant}) => {
        this.loading.loadingOff();
        if (tenant.homePageOn) {

          // here
          if(page?.isHomePage && page?.status === 'published' && !page['newHomePage'] ) {
            return this.router.parseUrl('/pages/' + page.url);
          }
          // here

          return true;
        } else {
          return this.router.parseUrl('/courses');
        }
      })
    );
  }


}
