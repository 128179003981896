import {Component, OnInit} from '@angular/core';
import {createSelector, select, Store} from '@ngrx/store';
import {AppState} from '../store';
import {TenantInfo} from '../models/tenant.model';
import {UserPermissions} from '../models/user-permissions.model';
import {selectTenantInfo, selectUserPermissions} from '../store/selectors';
import {filter, first, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {isPlanAllowed} from '../shared/allow-plan/is-plan-allowed';
import {Sha256} from '@aws-crypto/sha256-browser'
import {toHexString} from '../common/crypto-utils';
import {ActivatedRoute} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {TenantService} from '../services/tenant.service';
import {getPlatformSubdomain} from '../common/platform-utils';

declare const fbq;

interface FacebookIntegrationData {
  tenant: TenantInfo,
  permissions: UserPermissions

}

const selectFacebookIntegrationData = createSelector(
  selectTenantInfo,
  selectUserPermissions,
  (tenant, permissions) => {
    return {
      tenant,
      permissions
    }
  }
);

// these subdomains are excluded from the Facebook platform pixel installation
const excludedSubdomains = [
  'living-lights',
  'brandgaytor',
  'herewithin',
  'shamanicsoulstaracademy',
  'bamboobeginnings',
  'christinegold',
  'healingwiththerev'
];
@Component({
    selector: "facebook-integration",
    template: "",
    standalone: true
})
export class FacebookIntegrationComponent implements OnInit {

  initialized = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cookies: CookieService,
    private tenantService:TenantService) {

  }

  ngOnInit() {

    this.store
      .pipe(
        select(selectFacebookIntegrationData),
        filter(data => !!data.permissions && !this.initialized),
        first(),
        tap(data => this.initFacebookPixel(data))
      )
      .subscribe();

  }

  private async initFacebookPixel(data: FacebookIntegrationData) {

    console.log(`Setting up Facebook Pixel`, data);

    const {permissions} = data;

    const tenant = this.tenantService?.tenant;

    const facebookConfig = tenant?.facebookConfig;

    // determine which Facebook pixel to use - platform or course creator pixel
    let facebookPixelId = null;

    const accountCreationEvent = location.search?.includes('accountCreationEvent=true');

    const platformPlanPurchasedEvent = location.search?.includes("platformPlanPurchasedEvent=true");

    const isStudent = !permissions?.isAdmin && !permissions?.isCourseAdmin && !permissions?.isPlatformAdmin;

    console.log(`FACEBOOK accountCreationEvent: ${accountCreationEvent} | ${location.search}`);

    // if this is a platform customer, use the platform pixel
    if (
      permissions.isAdmin || accountCreationEvent || platformPlanPurchasedEvent) {

      const currentSubdomain = getPlatformSubdomain();

      if (excludedSubdomains.includes(currentSubdomain)) {
        console.log(`FACEBOOK Facebook Pixel NOT installed: subdomain ${currentSubdomain} is excluded.`);
        return;
      }

      facebookPixelId = environment.facebook.platformPixelId;
      console.log(`FACEBOOK Platform Facebook Pixel Installed, Pixel Id ${facebookPixelId}`);
    }
    // if the user is a student and the creator has at least the Pro plan
    // and this is the creator custom domain
    // and the Facebook integration is enabled,
    // then use the creator pixel
    else if (tenant && isStudent && isPlanAllowed(tenant, "pro") && facebookConfig?.enabled &&
    !getPlatformSubdomain()) {
      facebookPixelId = facebookConfig?.facebookCreatorPixel;
      console.log(`FACEBOOK Course Creator Facebook Pixel Installed, Pixel Id ${facebookPixelId}`);
    } else {
      console.log("FACEBOOK Facebook pixel not installed.");
    }

    if (facebookPixelId) {

      console.log(`FACEBOOK Installing the Facebook Pixel with Pixel Id ${facebookPixelId}`);

      // install the Facebook pixel script - with either the platform pixel or the course creator pixel
      eval("!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');");

      if (fbq) {

        console.log(`FACEBOOK Initializing the Facebook tracking code.`)

        if (permissions.isAdmin || accountCreationEvent) {

          console.log(`FACEBOOK Initializing the Facebook tracking code WITH an email hash.`)

          // hash the customer email
          const hash = new Sha256();
          hash.update(data?.tenant['email']);
          const output = await hash.digest();
          const hashedEmail = toHexString(output);

          console.log(`FACEBOOK Hashed email ${data?.tenant['email']} ${hashedEmail}`)

          // get the Facebook click Id
          const fbcid = this.cookies.get('_fbc');

          console.log(`FACEBOOK Content of the _fbc cookie: ${fbcid}`);

          const  fbc = fbcid?.split('.')?.pop();

          console.log(`FACEBOOK Extracted Facebook click Id (fbc): ${fbc}`);

          const dataSentToFacebook:any = {
            em: hashedEmail
          };

          if (fbc) {
            dataSentToFacebook.fbc = fbc;
          }

          console.log(`FACEBOOK Data sent to Facebook: ${JSON.stringify(dataSentToFacebook)}`);

          // initialize the tracking code
          // docs https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
          fbq('init', facebookPixelId, dataSentToFacebook);
        }
        else {
          console.log(`Initializing the Facebook tracking code without email hash.`)
          fbq('init', facebookPixelId);
        }

        // report a page view event - don't report page views for admins
        if (isStudent && facebookConfig?.enabled) {
          fbq('track', 'PageView');
        }

      }

      this.initialized = true;

    }

  }


}



