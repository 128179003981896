import {Injectable} from '@angular/core';
import {UserLessonStatus} from '../models/user-lesson-status';
import {TenantService} from './tenant.service';
import {from, Observable} from 'rxjs';
import {first, map, tap} from 'rxjs/operators';
import { collection, collectionData, deleteDoc, doc, Firestore, setDoc } from '@angular/fire/firestore';
import {readDocs} from '../common/firestore-utils';

@Injectable({
  providedIn: 'root'
})
export class UserLessonStatusDbService {


  constructor(
    private tenant: TenantService,
    private firestore: Firestore) {

  }

  loadLessonsWatchedByCourse(userId:string, courseId:string): Observable<UserLessonStatus[]> {

    console.log(`PERFORMANCE loadLessonsWatchedByCourse ${userId} ${courseId}`);

    const lessonsStatusListPath = `schools/${this.tenant.id}/users/${userId}/lessonsWatchedPerCourse/${courseId}/lessonsWatched`;

    const lessonsStatusList$ = readDocs<UserLessonStatus>(collection(this.firestore, lessonsStatusListPath));

    return lessonsStatusList$
      .pipe(
        map((lessonStatusList:UserLessonStatus[]) => lessonStatusList.map(lessonStatus => {return {...lessonStatus, courseId}})),
        tap(data => console.log(`PERFORMANCE loadLessonsWatchedByCourse ${userId} ${courseId} ${data}`)),
        first()
      )
  }


  saveLessonStatus(userId:string, lessonStatus: UserLessonStatus):Observable<any> {

    const lessonStatusPath = `schools/${this.tenant.id}/users/${userId}/lessonsWatchedPerCourse/${lessonStatus.courseId}/lessonsWatched/${lessonStatus.id}`;

    if (lessonStatus.watched) {
      return from(setDoc(doc(this.firestore, lessonStatusPath), {
        watched:true
      }));
    }
    else {
      return from(deleteDoc(doc(this.firestore, lessonStatusPath)));
    }
  }


}
